import React from "react";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppNavigationBar from "./AppNavigationBar";
import {
	CircularProgress,
	Container,
	Typography,
	IconButton,
} from "@mui/material";
import { AuthProvider } from "../contexts/AuthContext";
import PrivateRoutes from "../shared/PrivateRoutes";
import dayjs from "dayjs";
import no_nb from "dayjs/locale/nb";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FacebookIcon from "@mui/icons-material/Facebook";
import LanguageTwoToneIcon from "@mui/icons-material/LanguageTwoTone";
import { MailTwoTone } from "@mui/icons-material";
import PublicCalendar from "./PublicCalendar";
import PublicPage from "./PublicPage";
import Information from "./Information";
import UserCalendar from "./UserCalendar";
import ProcessPayment from "./ProcessPayment";
import Activate from "./Activate";
import Store from "./Store";
import styled from "@mui/material/styles/styled";
import { RosterToday } from "./RosterToday";
import { TicketControl } from "./TicketControl";
import { LiftLog } from "./LiftLog";
//import { EndRoster } from "./EndRoster";

const AdminHome = React.lazy(() => import("./AdminHome"));
const Login = React.lazy(() => import("./Login"));
const Logout = React.lazy(() => import("./Logout"));
const Profile = React.lazy(() => import("./Profile"));

const loader = (elem: JSX.Element) => (
	<Suspense
		fallback={
			<Container>
				<CircularProgress />
			</Container>
		}
	>
		{elem}
	</Suspense>
);

const theme = createTheme({
	palette: {
		primary: { main: "#03a9f4" },
	},
});

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const App = () => {
	dayjs.locale(no_nb);
	return (
		<ThemeProvider theme={theme}>
			<AuthProvider>
				<BrowserRouter>
					<Routes>
						<Route
							path="/publiccalendar"
							element={loader(<PublicCalendar />)}
						/>
						<Route path="*" element={<Main />} />
					</Routes>
				</BrowserRouter>
			</AuthProvider>
		</ThemeProvider>
	);
};

function BottomAttachedBox() {
	return (
		<Box
			sx={{
				display: "flex",
				position: "fixed", // Fixed position
				bgcolor: "grey.200",
				bottom: 0, // Attached to bottom
				left: 0,
				width: "100%", // Take full width
				p: 1, // Padding
				justifyContent: "center", // Center text
			}}
		>
			<Typography variant="subtitle1">
				Bak-Olsen - org.nr 994575678
				<IconButton
					href="mailto:post@bakolsen.no"
					target="_blank"
					color="inherit"
					aria-label="post@bakolsen.no"
				>
					<MailTwoTone />
				</IconButton>
				<IconButton
					href="http://bakolsen.no"
					target="_blank"
					color="inherit"
					aria-label="bakolsen.no"
				>
					<LanguageTwoToneIcon />
				</IconButton>
				<IconButton
					href="https://www.facebook.com/bakolsen"
					target="_blank"
					color="inherit"
					aria-label="facebook"
				>
					<FacebookIcon />
				</IconButton>
			</Typography>
		</Box>
	);
}

const Main = () => {
	return (
		<>
			<nav>
				<AppNavigationBar />
			</nav>
			<Offset />
			<main>
				<Box height="90%" overflow-Y="scroll">
					<Routes>
						<Route element={<PrivateRoutes />}>
							<Route
								path="/calendar"
								element={loader(<UserCalendar />)}
							/>
							<Route
								path="/admin"
								element={loader(<AdminHome />)}
							/>
							<Route
								path="/activate"
								element={loader(<Activate />)}
							/>
							<Route
								path="/profile"
								element={loader(<Profile />)}
							/>

							<Route
								path="/rosterToday"
								element={loader(<RosterToday />)}
							/>

							{/*<Route
								path="/skolevakt"
								element={loader(
									<RosterToday variant="school" />
								)}
							/>/*/}

							<Route
								path="/rosterToday/control"
								element={loader(<TicketControl />)}
							/>

							<Route
								path="/rosterToday/liftLog"
								element={loader(<LiftLog />)}
							/>

							<Route
								path="/ks"
								element={loader(<LiftLog variant="school" />)}
							/>

							{/*<Route
								path="/rosterToday/endRoster"
								element={loader(<EndRoster />)}
							/> */}

							<Route path="/" element={loader(<Information />)} />
						</Route>

						{/* All public pages goes here */}
						<Route
							path="/public"
							element={loader(<PublicPage />)}
						/>

						<Route path="/store" element={loader(<Store />)} />

						<Route
							path="/processPayment/:orderId"
							element={loader(<ProcessPayment />)}
						/>
						{/* <Route path="/terms" element={loader(<Terms />)} /> */}
						<Route path="/logout" element={loader(<Logout />)} />
						<Route path="/vippslogin" element={loader(<Login />)} />
					</Routes>
				</Box>
				<Offset />
				<BottomAttachedBox />
			</main>
		</>
	);
};

export default App;
